<template>
 <secondary-page-layout :title="product && product.title ? product.title: null">
   <div class="ps-4 pe-3 injected-html" v-html="text">
      {{text}}
   </div>
   <router-link :to="{name:'sos.form', params: {id: $route.params.id}}" class="primary-btn bottom-page-btn">{{$t('sendRequest')}}</router-link>
 </secondary-page-layout>
</template>

<script>
export default {
  name: "SosInstruction",
  data() {
    return {
      product: null,
      text: ''
    }
  },
  mounted() {
    this.getInsuranceCaseById();
  },
  methods: {
    async getInsuranceCaseById() {
      const { data } = await window.axios.get(`insurance-cases/${this.$route.params.id}`);
      this.product = data;
      this.text = this.product.manual;
    }
  }
}
</script>

<style scoped>

</style>
