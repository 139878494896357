import { render, staticRenderFns } from "./SosInstruction.vue?vue&type=template&id=53181f5c&scoped=true"
import script from "./SosInstruction.vue?vue&type=script&lang=js"
export * from "./SosInstruction.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53181f5c",
  null
  
)

export default component.exports